import React, { useState } from 'react';
// import formSuccess from '../form-success/formSuccess'


const SuccessModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded shadow-lg text-center">
                <h2 className="text-2xl font-bold mb-4 text-neutral-900 tracking-tighter">You're in line!</h2>
                <h3 className="text-xl mb-4 text-neutral-900 tracking-tight">Doors open soon &#128526;</h3>
                <p className="mb-4 text-neutral-500">While you wait, help us learn more by filling out our <a href="https://forms.gle/xhNCFk7mX9aWMHGZ8" target="_blank" rel="noopener noreferrer" className="text-indigo-700 underline">live music survey</a>.</p>
                <button onClick={onClose} className="bg-indigo-700 text-white py-2 px-4 rounded">Close</button>
            </div>
        </div>
    );
};

export default function EmailSub() {

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateEmail(email)) {
            setError('');

            const form = e.target;
            const data = new FormData(form);

            try {
                const response = await fetch(form.action, {
                    method: form.method,
                    body: data,
                    headers: {
                        'Accept': 'application/json'
                    }
                });

                if (response.ok) {
                    setIsModalOpen(true); // Show modal on successful submission
                } else {
                    setError('There was a problem with your submission. Please try again.');
                }
            } catch (error) {
                setError('There was a problem with your submission. Please try again.');
            }
        } else {
            setError('Please enter a valid email address.');
        }
    };
    
    return (
        <div>
            <form className="w-full mx-auto" name="emailSub" method="post" netlify onSubmit={handleSubmit}>
                    <div className="flex md:flex-row md:flex-nowrap flex-wrap content-center">
                    <input type="hidden" name="form-name" value="emailSub" />
                    <input 
                            className="md:basis-2/3 bg-white w-full text-gray-700 text-sm md:mr-3 mr-0 mb-4 md:mb-0 py-4 px-8 focus:outline-none rounded-full" 
                            type="email" 
                            name="email" 
                            placeholder="Enter Email Address" 
                            aria-label="Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    <button className="md:basis-1/3 w-full bg-indigo-700 text-sm flex-shrink text-white py-4 rounded-full" type="submit" value="Join Waitlist">Join Waitlist</button>
                    </div>
                    {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            </form>
        <SuccessModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
    );

}